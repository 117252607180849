import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const { Button } = BaseRowTheme;

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: ``,
    headline: `after:bg-primary-700 text-headline-3xs sm:text-headline-3xs uppercase after:h-2.5`,
  },
  variants: {
    size: {
      primary: {
        base: `gap-x-3 gap-y-6 sm:gap-x-4.5 md:gap-x-6 md:gap-y-6`,
      },
    },
  },
});

export default Object.assign(Row, { Button });
