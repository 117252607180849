/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slider_slide_caption_variant_default": "capitalize",
  "slider_slide_caption_colors_primary": "text-gray-700",
  "slider_slide_caption_size_medium": "text-headline-3xs mt-3 mb-2",
  "slider_slide_caption_size_large": "text-headline-3xs mt-3 mb-2",
  "slider_slide_description_colors_primary": "text-black",
  "slider_slide_description_variant_default": "line-clamp-2",
  "slider_slide_description_size_medium": "text-headline-2xs sm:text-headline-xs",
  "slider_slide_description_size_large": "text-headline-2xs sm:text-headline-xs",
  "slider_slide_group_variant_default": "sm:!snap-none",
  "slider_slide_group_colors_primary": "scrollbar-none",
  "slider_slide_group_size_medium": "!p-0 gap-3",
  "slider_slide_group_size_large": "!p-0 gap-4 md:gap-5",
  "slider_slide_image_variant_default": "after:hidden",
  "slider_slide_image_colors_primary": "",
  "slider_slide_image_size_medium": "w-auto min-w-60 md:min-w-75 [&_picture]:aspect-[1.89/1] [&_img]:aspect-[1.89/1] md:[&_picture]:aspect-[2/1] md:[&_img]:aspect-[2/1]",
  "slider_slide_image_size_large": "w-auto min-w-60 md:min-w-75 [&_picture]:aspect-[1/1.32] [&_img]:aspect-[1/1.32] md:[&_picture]:aspect-[1/1.5] md:[&_img]:aspect-[1/1.5]",
  "slider_slide_variant_default": "flex flex-col relative snap-center",
  "slider_slide_colors_primary": "",
  "slider_slide_size_medium": "gap-2",
  "slider_slide_size_large": "",
  "slider_headline_colors_primary": "text-black",
  "slider_headline_variant_default": "",
  "slider_headline_size_medium": "text-headline-3xs",
  "slider_headline_size_large": "text-headline-3xs",
  "slider_colors_primary": "",
  "slider_variant_default": "[&_a]:flex-1",
  "slider_size_medium": "flex-col flex gap-3 overflow-hidden !pt-0",
  "slider_size_large": "flex-col flex gap-3 overflow-hidden !pt-0",
  "arrow_variant_right": "absolute top-[50%] -translate-y-full right-25 z-10 cursor-pointer",
  "arrow_variant_left": "absolute top-[50%] -translate-y-full left-25 z-10 cursor-pointer rotate-180",
  "arrow_colors_primary": "text-gray-300",
  "arrow_size_medium": "hidden md:block",
  "arrow_size_large": "hidden md:block",
  "navigation_item_variant_default": "rounded-full",
  "navigation_item_colors_primary": "bg-gray-400 data-[active=true]:bg-gray-600",
  "navigation_item_size_medium": "w-3 h-3",
  "navigation_item_size_large": "",
  "navigation_variant_default": "flex justify-center sm:hidden",
  "navigation_colors_primary": "",
  "navigation_size_medium": "gap-3 pt-6 md:pt-9",
  "navigation_size_large": "",
  "variant_default": "relative rounded md:rounded-none overflow-hidden",
  "colors_primary": "",
  "size_medium": "",
  "size_large": "",
  "link": {
    "variant_default": "hidden"
  }
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ArticleSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;