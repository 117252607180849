/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "image": {
      "size_default": "min-w-25 sm:min-w-37.5 max-w-40 sm:max-w-60"
    }
  },
  "swiper": {},
  "disclaimer_line_variant_default": "",
  "disclaimer_line_colors_default": "",
  "disclaimer_line_size_default": "mb-1 md:mb-4",
  "disclaimer_colors_default": "text-gray-600",
  "disclaimer_size_default": "text-ui-md",
  "disclaimer_variant_default": "",
  "title_variant_default": "!font-normal",
  "title_colors_default": "",
  "title_size_default": "text-headline-3xs",
  "price_variant_default": "after:content-[\":-\"]",
  "price_colors_default": "",
  "price_size_default": "text-headline-3xs",
  "colors_default": "",
  "size_default": "w-full",
  "variant_default": "relative"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AffiliateGallery");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;