import { tw } from '@/utils/tw';
import BaseNavigationTheme from 'base/components/Navigation/theme';

const { Button } = BaseNavigationTheme;

const Link = tw.theme({
  variants: {
    colors: {
      bar: ['text-white'],
      drawer: ['text-black'],
    },
    size: {
      bar: ['p-1.5'],
      controls: ['p-0'],
      drawer: ['py-2.5'],
    },
    variant: {
      bar: ['text-headline-2xs', 'inline-block'],
      drawerPrimary: ['text-headline-2xs', 'block'],
      drawerSecondary: ['text-ui-md', 'block'],
    },
  },
});

export default Object.assign({}, { Button, Link });
