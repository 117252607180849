import { tw } from '@/utils/tw';
import BaseRecommendedArticlesTheme from 'base/components/RecommendedArticles/theme';

const Skeleton = tw.theme({
  extend: BaseRecommendedArticlesTheme.Skeleton,
  base: ['h-[350px]'],
});

const ArticleTeaser = tw.theme({
  extend: BaseRecommendedArticlesTheme.ArticleTeaser,
  slots: {
    headline: ['text-headline-2xs', 'sm:text-headline-xs', 'md:text-headline-xs'],
  },
});

const RecommendedArticles = tw.theme({
  extend: BaseRecommendedArticlesTheme,
  slots: {
    base: ['relative', 'md:py-0', 'px-6', 'bg-white'],
    headline: [
      'flex',
      'items-center',
      'whitespace-nowrap',
      'break-normal',
      'uppercase',
      'after:block',
      'after:bg-primary-700',
      'mb-4',
      'md:mb-4.5',
      'sm:text-headline-3xs',
      'after:ml-3',
      'after:h-2.5',
      'after:w-full',
    ],
    list: ['gap-3', 'sm:gap-6'],
    listItem: ['mb-6', 'sm:mb-0', 'md:mb-6'],
  },
});

export default Object.assign(RecommendedArticles, { ArticleTeaser, Skeleton });
