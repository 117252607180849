import { tw } from '@/utils/tw';
import BaseButtonTheme from 'base/components/Button/theme';

const { Icon } = BaseButtonTheme;

const Button = tw.theme({
  extend: BaseButtonTheme,
  variants: {
    colors: {
      primary: [
        'text-white',
        'bg-primary-700',
        'border-primary-700',
        'hover:bg-primary-600',
        'active:bg-primary-800',
        'focus:bg-primary-800',
        'focus:ring-royal-blue-200',
      ],
      secondary: [
        'text-black',
        'bg-gray-100',
        'border-gray-100',
        'hover:bg-gray-200',
        'hover:border-gray-200',
        'active:bg-gray-300',
        'active:border-gray-300',
        'focus:bg-gray-300',
        'focus:border-gray-300',
        'focus:ring-royal-blue-200',
      ],
    },
    size: {
      small: ['h-8', 'px-5', 'py-2', 'text-headline-3xs', 'gap-1.5'],
      medium: ['h-9', 'px-6', 'py-2.25', 'text-headline-2xs', 'gap-2.5'],
      large: ['h-12', 'px-6', 'py-3.5', 'text-headline-2xs', 'gap-2.5'],
    },
  },
});

export default Object.assign(Button, { Icon });
