import { tw } from '@/utils/tw';
import BaseAuthorDetailsTheme from 'base/components/AuthorDetails/theme';

const { Image, SoMe } = BaseAuthorDetailsTheme;

const AuthorDetails = tw.theme({
  extend: BaseAuthorDetailsTheme,
  variants: {
    variant: {
      default: {
        base: ['bg-gray-100', 'p-3', 'sm:p-6', 'gap-x-6', 'gap-y-3', 'm-4', 'max-w-none', 'w-auto'],
        caption: ['prose'],
        description: ['prose', 'my-0'],
        email: [],
        headline: ['text-headline-2xl', 'mb-0'],
        soMeGroup: [],
      },
    },
  },
});

export default Object.assign(AuthorDetails, { Image, SoMe });
